import { useEffect, useRef, useState } from "react";
import { CloseTrimmedIcon, CycleTrimmedIcon, DoneIcon, EditTrimmedIcon } from "@contentful/f36-icons";
import { Button, Textarea } from "@contentful/forma-36-react-components";

interface EditableCellProps {
  value: string;
  valueID: string;
  onChange: any;
  resetValue: any;
}

export const EditableCell = (props: EditableCellProps) => {
  const { value, resetValue, onChange, valueID } = props;
  const [Drawable, setDrawable] = useState(true);
  const [text, setText] = useState(value ?? "");
  const [newText, setNewText] = useState(value ?? "");

  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    let element = ref.current;
    if (!element) return;
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "em";
    element.style.maxHeight = "10em";
  }, []);

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleInputChange = (e: any) => {
    setText(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
    e.target.style.maxHeight = "10em";
  };
  const handleSaveClick = () => {
    setDrawable(!Drawable);
    setNewText(text);
    if (onChange) {
      onChange(text);
    }
  };
  const handleCancelClick = () => {
    setText(newText);
    setDrawable(!Drawable);
  };
  const ResetData = () => {
    setText(value);
    if (resetValue) {
      resetValue(value);
    }
    setDrawable(false);
  };

  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td style={{ width: "95%" }}>
            <Textarea
              id={valueID}
              disabled={Drawable}
              textareaRef={ref}
              value={text}
              onChange={handleInputChange}
              resize="none"
            />
          </td>
          <td>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Button
                      buttonType="naked"
                      size="small"
                      style={{ width: " 4em" }}
                      onClick={() => {
                        ResetData();
                      }}
                    >
                      <CycleTrimmedIcon variant="secondary" size="medium" />
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      buttonType="naked"
                      size="small"
                      style={{ width: " 4em" }}
                      disabled={!Drawable}
                      onClick={() => {
                        setDrawable(!Drawable);
                      }}
                    >
                      <EditTrimmedIcon size="medium" />
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      buttonType="naked"
                      size="small"
                      style={{ width: " 4em" }}
                      disabled={Drawable}
                      onClick={handleCancelClick}
                    >
                      <CloseTrimmedIcon variant="negative" size="medium" />
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      buttonType="naked"
                      size="small"
                      style={{ width: " 4em" }}
                      disabled={Drawable}
                      onClick={handleSaveClick}
                    >
                      <DoneIcon variant="positive" size="medium" />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
