import { Buffer } from "buffer";

export class ApiClient {
  secretKey: string;
  apiKey: string;
  exportFormat: string;
  exportExtension: string;
  mode: string;

  constructor(secretKey: string, apiKey: string, exportFormat: string, mode: string) {
    this.secretKey = secretKey;
    this.apiKey = apiKey;
    this.mode = mode;
    this.exportFormat = exportFormat ? exportFormat : "json";
    this.exportExtension = this.exportFormat === "xliff" ? "xlf" : this.exportFormat;
  }

  createQuote = async (title: string, notes: string, user: any, sourceLanguage: string, targetLanguages: string[]) => {
    // HTTP method
    let method = "POST";
    let headerContentType = "application/json";
    let urlPath = "/quotes";

    // Request Body
    let body = {
      name: title,
      notes: notes,
      createdBy: user.firstName + " " + user.lastName,
      sourceLanguage: sourceLanguage,
      targetLanguages: targetLanguages,
    };

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);

    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      console.log(res);
      throw res.statusText;
    });
  };

  saveDocuments = async (
    quoteId: number,
    documentId: string,
    content: string,
    contentType: string,
    user: any,
    sourceLanguage: string,
    lastModified: string,
  ) => {
    // HTTP method
    let method = "POST";
    let headerContentType = "application/json";
    let urlPath = "/quotes/" + quoteId + "/documents";

    let buff = Buffer.from(content);
    let base64data = buff.toString("base64");

    // Request Body
    let body = {
      userName: user.email,
      documents: [
        {
          documentType: contentType,
          addedOn: new Date().toISOString(),
          language: sourceLanguage,
          id: documentId,
          lastModified: new Date().toISOString(),
          fileName: documentId + "." + this.exportExtension,
          title: documentId,
          contents: base64data,
        },
      ],
    };

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);

    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.ok) {
        return res;
      }
      console.log(res);
      throw res.statusText;
    });
  };

  updateDocuments = async (
    quoteId: number,
    documentId: string,
    content: string,
    contentType: string,
    user: any,
    sourceLanguage: string,
  ) => {
    // HTTP method
    let method = "PUT";
    let headerContentType = "application/json";
    let urlPath = "/quotes/" + quoteId + "/documents";

    let buff = Buffer.from(content);
    let base64data = buff.toString("base64");

    // Request Body
    let body = {
      userName: user.email,
      documents: [
        {
          documentType: contentType,
          addedOn: new Date().toISOString(),
          language: sourceLanguage,
          id: documentId,
          lastModified: new Date().toISOString(),
          fileName: documentId + "." + this.exportExtension,
          title: documentId,
          contents: base64data,
        },
      ],
    };

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);

    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.ok) {
        return res;
      }
      console.log(res);
      throw res.statusText;
    });
  };

  submitQuote = async (quoteId: number, user: any) => {
    // HTTP method
    let method = "POST";
    let headerContentType = "application/json";
    let urlPath = "/quotes/submit";

    // Request Body
    let body = {
      quoteId: quoteId,
      userName: user.email,
    };

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);

    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.ok) {
        return res;
      }
      console.log(res);
      throw res.statusText;
    });
  };

  sendSingleItemForUpdate = async (
    documentId: string,
    quoteId: number,
    contentType: string,
    fields: any[],
    user: any,
    sourceLanguage: string,
  ) => {
    return this.exportContent(fields, sourceLanguage).then((content: string) => {
      this.updateDocuments(quoteId, documentId, content, contentType, user, sourceLanguage).then((res: any) => {
        return res;
      });
    });
  };

  sendSingleItemForTranslation(
    id: string,
    contentType: string,
    fields: any[],
    user: any,
    sourceLanguage: string,
    targetLanguages: string[],
  ) {
    let quoteId: number = 0;
    let title = `${contentType} - ${id} - ${new Date().getFullYear()}-${new Date().getMonth()}-${
      new Date().getDay() < 10 ? "0" : ""
    }${new Date().getDay()}`;
    let notes = `Request for quote submitted through GPI Translation Services Connector for Contentful.<br />User E-mail: ${user.email}`;

    return this.createQuote(title, notes, user, sourceLanguage, targetLanguages)
      .then((result: any) => {
        quoteId = result.id;
        return this.exportContent(fields, sourceLanguage).then((content: string) => {
          this.saveDocuments(quoteId, id, content, contentType, user, sourceLanguage, "").then((res: any) => {
            return res;
          });
        });
      })
      .then((result: any) => {
        return this.submitQuote(quoteId, user);
      });
  }

  sendMultipleItemsForTranslation(
    title: string,
    notes: string,
    items: any[],
    user: any,
    sourceLanguage: string,
    targetLanguages: string[],
  ) {
    let quoteId: number = 0;

    return this.createQuote(title, notes, user, sourceLanguage, targetLanguages)
      .then((result: any) => {
        quoteId = result.id;

        const queue = this.multiQueue(6); // most browsers I think limit to 6 simultaneous AJAX anyway

        let promises: any[] = [];

        items.forEach((item) => {
          item.fields["_gpiInternalName"] = item.displayTitle;

          let promise = queue(() =>
            this.exportContent(item.fields, sourceLanguage).then((content: string) => {
              return this.saveDocuments(quoteId, item.id, content, item.contentType, user, sourceLanguage, "");
            }),
          );

          promises.push(promise);
        });

        return Promise.all(promises);
      })
      .then(() => {
        return this.submitQuote(quoteId, user);
      });
  }

  getQuote = async (quoteId: number) => {
    // HTTP headers
    let method = "GET";
    let headerContentType = "application/json";
    let urlPath = "/quotes/" + quoteId;

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);

    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      console.log(res);
      throw res.statusText;
    });
  };

  getQuotes = async () => {
    // HTTP headers
    let method = "GET";
    let headerContentType = "application/json";
    let urlPath = "/quotes";

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);

    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      console.log(res);
      throw res.statusText;
    });
  };

  getDocuments = async (quoteId: number, requestId: string) => {
    // HTTP headers
    let method = "GET";
    let headerContentType = "application/json";
    let urlPath = "/quotes/" + quoteId + "/documents/" + requestId;

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);
    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
    }).then((res) => {

      if (res.ok) {
        return res.json();
      }
      throw res.statusText;
    });
  };

  closeProject = async (quoteId: number) => {
    // HTTP method
    let method = "PUT";
    let headerContentType = "application/json";
    let urlPath = "/quotes/" + quoteId + "/close";

    // Signature
    const date = this.getGPIDateString();
    let canonicalMessage = this.createCanonicalMessage(method, date, headerContentType, urlPath);
    let signature = await this.createSignature(canonicalMessage);

    // Request Headers
    let headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", date);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", headerContentType);

    // Request
    return fetch("https://api.globalizationpartners.com" + urlPath, {
      method: method,
      headers: headers,
    }).then((res) => {
      if (res.ok) {
        return res;
      }
      console.log(res);
      throw res.statusText;
    });
  };

  getGPIDateString() {
    let dateString = new Date().toString().split("(")[0].trim();
    dateString = [dateString.slice(0, dateString.length - 2), ":", dateString.slice(dateString.length - 2)].join("");

    return dateString;
  }

  createCanonicalMessage(method: string, date: string, contentType: string, urlPath: string) {
    let canonicalMessage = method + "\n";
    canonicalMessage += "\n";
    if (contentType) {
      canonicalMessage += contentType;
    }
    canonicalMessage += "\n";
    canonicalMessage += "\n";
    canonicalMessage += "x-gpi-api-key:" + this.apiKey + "\n";
    canonicalMessage += "x-gpi-date:" + date + "\n";
    canonicalMessage += urlPath;

    return canonicalMessage;
  }

  exportContent(fields: any, srcLanguage: string): any {
    if (this.exportFormat === "xliff") {
      return new Promise(function (resolve, reject) {
        const createxliff = require("xliff/cjs/createxliff");
        createxliff(srcLanguage, srcLanguage, fields, fields, "ns-gpi-contentful-tsc", function (err: any, res: any) {
          if (err) {
            reject(err);
            return;
          }
          resolve(res);
        });
      });
    }

    return new Promise(function (resolve, reject) {
      try {
        resolve(JSON.stringify(fields, null, "\t"));
      } catch (ex) {
        reject(ex);
      }
    });
  }

  createSignature = async (canonicalMessage: string) => {
    const encoder = new TextEncoder();

    const importKey = await crypto.subtle.importKey(
      "raw",
      encoder.encode(this.secretKey),
      { name: "HMAC", hash: { name: "SHA-256" } },
      false,
      ["sign", "verify"],
    );

    const signature = await crypto.subtle.sign("HMAC", importKey, encoder.encode(canonicalMessage));

    const signatureArray = Array.from(new Uint8Array(signature));
    return btoa(String.fromCharCode.apply(null, signatureArray));
  };

  multiQueue(max: number) {
    max = isNaN(max) || max < 1 ? 1 : max;

    const q = new Array(max).fill(0).map(() => Promise.resolve());

    let index = 0;

    const add = (cb: any, ...args: any) => {
      index = (index + 1) % max;
      return q[index].then(() => cb(...args));
    };

    return add;
  }
}
