import React, { useCallback, useEffect } from "react";
import { ConfigAppSDK } from "@contentful/app-sdk";
import {
  Heading,
  Form,
  Workbench,
  Paragraph,
  TextInput,
  Notification,
  RadioButtonField,
} from "@contentful/forma-36-react-components";
import logo from "../logo.png";

export interface AppInstallationParameters {}

interface ConfigProps {
  sdk: ConfigAppSDK;
}

const Config = (props: ConfigProps) => {
  // App Parameters
  const [parameters, setParameters] = React.useState<AppInstallationParameters>({});
  const [authToken, setAuthToken] = React.useState("");
  const [secretKey, setSecretKey] = React.useState("");
  const [exportFormat, setExportFormat] = React.useState("");

  const onChangeSecretKey = (newSecretKey: string) => {
    let params: any = parameters;
    params.secretKey = newSecretKey;
  };

  const onChangeAuthToken = (newAuthToken: string) => {
    let params: any = parameters;
    params.authToken = newAuthToken;
  };

  const onSelectExportFormat = (checked: boolean, format: string) => {
    let params: any = parameters;
    params.exportFormat = format;
    setExportFormat(format);
  };

  const onConfigure = useCallback(async () => {
    // This method will be called when a user clicks on "Install"
    // or "Save" in the configuration screen.
    // for more details see https://www.contentful.com/developers/docs/extensibility/ui-extensions/sdk-reference/#register-an-app-configuration-hook

    // Get current the state of EditorInterface and other entities
    // related to this app installation
    const currentState = await props.sdk.app.getCurrentState();

    // Validations
    let params: any = parameters;

    if (!params.secretKey || !params.secretKey.trim()) {
      Notification.setDuration(2000);
      Notification.error("Please, specify a Secret Key.");
      return false;
    }

    if (!params.authToken || !params.authToken.trim()) {
      Notification.setDuration(2000);
      Notification.error("Please, specify an Auth Token.");
      return false;
    }

    return {
      // Parameters to be persisted as the app configuration.
      parameters,
      // In case you don't want to submit any update to app
      // locations, you can just pass the currentState as is
      targetState: currentState,
    };
  }, [parameters, props.sdk]);

  useEffect(() => {
    // `onConfigure` allows to configure a callback to be
    // invoked when a user attempts to install the app or update
    // its configuration.
    props.sdk.app.onConfigure(() => onConfigure());
  }, [props.sdk, onConfigure]);

  useEffect(() => {
    (async () => {
      // Get current parameters of the app.
      // If the app is not installed yet, `parameters` will be `null`.
      const currentParameters: AppInstallationParameters | null = await props.sdk.app.getParameters();

      if (currentParameters) {
        setParameters(currentParameters);
        let params: any = currentParameters;
        setAuthToken(params.authToken);
        setSecretKey(params.secretKey);
        setExportFormat(params.exportFormat ? params.exportFormat : "json");
      }

      // Once preparation has finished, call `setReady` to hide
      // the loading screen and present the app to a user.
      props.sdk.app.setReady();
    })();
  }, [props.sdk]);

  return (
    <Workbench>
      <div className="config-content">
        <div className="config-background"></div>
        <div className="config-container">
          <Form>
            <Heading>About GPI Translation Services Connector</Heading>
            <Paragraph>
              The GPI Translation Services Connector enables seamless translation workflows allowing users of Contentful
              to select, export and import web content for translation with just a few clicks.
            </Paragraph>
            <hr />
            <Heading>Configuration</Heading>
            <Paragraph>
              Please, enter the following parameters to get the connector working. If you don't have these parameters,
              please contact <b>support@globalizationpartners.com</b>.
            </Paragraph>
            <div className="f36-margin-bottom--m">
              <Paragraph className="f36-margin-bottom--m f36-margin-top--m">
                <b>Secret Key</b> (required)
              </Paragraph>
              <TextInput
                name="secret-key"
                type="text"
                value={secretKey}
                onChange={(e) => onChangeSecretKey(e.target.value)}
              />
            </div>
            <div className="f36-margin-bottom--m">
              <Paragraph className="f36-margin-bottom--m f36-margin-top--m">
                <b>Auth Token</b> (required)
              </Paragraph>
              <TextInput
                name="auth-token"
                type="text"
                value={authToken}
                onChange={(e) => onChangeAuthToken(e.target.value)}
              />
            </div>
            <div className="f36-margin-bottom--m">
              <Paragraph className="f36-margin-bottom--m f36-margin-top--m">
                <b>Export format</b> (required)
              </Paragraph>
              <div style={{ maxHeight: "120px", minHeight: "60px" }} className="f36-margin-top--m">
                <div style={{ display: "inline-block", marginRight: "20px" }}>
                  <RadioButtonField
                    name="rb-format-json"
                    id="rb-format-json"
                    value="json"
                    checked={exportFormat === "json"}
                    onChange={(e) => onSelectExportFormat(e.target.checked, "json")}
                    labelText="JSON"
                  />
                </div>
                <div style={{ display: "inline-block", marginRight: "20px" }}>
                  <RadioButtonField
                    name="rb-format-xliff"
                    id="rb-format-xliff"
                    value="xliff"
                    checked={exportFormat === "xliff"}
                    onChange={(e) => onSelectExportFormat(e.target.checked, "xliff")}
                    labelText="XLIFF"
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="config-footer">
          <img src={logo} alt="App logo" />
        </div>
      </div>
    </Workbench>
  );
};

export default Config;
