import { useEffect, useState } from "react";
import { DialogAppSDK } from "@contentful/app-sdk/dist/types/api.types";
import { ApiClient } from "../../gpi-api/apiClient";
import DynamicTable, { IHeader } from "../UI/DynamicTable";
import { v4 } from "uuid";
import { LanguageIcon } from "@contentful/f36-icons";
import { Button, Modal, TextLink } from "@contentful/f36-components";
import { OpenUpdateModal } from "../ModalActions/OpenModals";
import { Environment, createClient } from "contentful-management";
import { Paragraph, Spinner } from "@contentful/forma-36-react-components";

interface DialogProps {
  sdk: DialogAppSDK;
}

interface IFiles {
  id: string;
  fileName: string;
  wordCount: number;
  language: string;
  data: any;
}

interface ReducedFile {
  id: string;
  fileName: string;
  wordCount: number;
  language: string;
  actions: any;
}

export interface actionData {
  data: any;
  id: string;
  lang: string;
}

export const ProjectFilesReview = (props: DialogProps) => {
  const { sdk } = props;

  const [filesRow, setFilesRow] = useState<any[]>([]);
  const [environment, setEnvironment] = useState({} as Environment);
  const [entityUpdate, setEntityUpdate] = useState({} as any);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const header: IHeader[] = [
    { Name: "File", Field: "fileName" },
    { Name: "Words", Field: "wordCount" },
    { Name: "Language", Field: "language" },
    { Name: "Actions", Field: "actions" },
  ];

  const parameters: any = sdk.parameters.installation;
  const invocation = sdk.parameters.invocation as { type: string; data: { project: any } };
  const project = invocation.data as any;

  const GetData = async (client: ApiClient, project: any) => {
    const docs = await client.getDocuments(project.id, v4());

    const fileLines: IFiles[] = [];
    docs.documents.forEach((doc: any) => {
      const file: IFiles = {
        id: "",
        fileName: "",
        language: "",
        wordCount: 0,
        data: {},
      };

      const translatedString: string = Buffer.from(doc.contents, "base64").toString("utf-8");
      const translated = JSON.parse(translatedString);

      const translatedKey = Object.keys(translated);
      let words = 0;

      translatedKey.forEach((x) => {
        words += translated[x].split(" ").length;
      });

      file.id = doc.id;
      file.fileName = doc.documentType;
      file.wordCount = words;
      file.language = doc.language;
      file.data = translated;

      fileLines.push(file);
    });

    return fileLines;
  };

  const getEnvironment = () => {
    const cma = createClient({ apiAdapter: sdk.cmaAdapter });
    cma.getSpace(sdk.ids.space).then((space) => {
      space.getEnvironment(sdk.ids.environment).then((environment) => {
        setEnvironment(environment);
      });
    });
  };

  const getEntry = (id: string) => {
    return environment.getEntry(id).then((entry: any) => {
      return entry;
    });
  };

  const SendUpdateToGPI = () => {
    
    // const client = new ApiClient(parameters.secretKey, parameters.authToken, parameters.exportFormat, "prod");
    // if (environment === undefined || environment) getEnvironment();
    // setShowSpinner(true);

    // const queue = multiQueue(6); // most browsers I think limit to 6 simultaneous AJAX anyway

    // let promises: any[] = [];

    // Object.keys(entityUpdate).forEach((key) => {
    //   if (key === "lang") return;
    //    let promise = queue(() =>
    //      client.sendSingleItemForUpdate(documentId, project.id, contentType, fields, sdk.user, sourceLanguage),
    //    );

    //    entityUpdate.forEach((item: any) => {
    //      promises.push(promise);
    //    });
    // });

    // Promise.all(promises);

    // setShowSpinner(false);
  };

  const ApplyLocal = () => {
    let promises: any[] = [];
    setShowSpinner(true);
    if (environment === undefined || environment) getEnvironment();

    Object.keys(entityUpdate).forEach((key) => {
      if (key === "lang") return;
      promises.push(getEntry(key));
    });

    Promise.all(promises).then((entries) => {
      entries.forEach((entry, i) => {
        const keys = Object.keys(entityUpdate[entry.sys.id]);
        keys.forEach((key) => {
          entry.fields[key][entityUpdate.lang] = entityUpdate[entry.sys.id][key];
        });

        entry.update();

        setShowSpinner(false);
      });
    });
  };

  const multiQueue = (max: number) => {
    max = isNaN(max) || max < 1 ? 1 : max;

    const q = new Array(max).fill(0).map(() => Promise.resolve());

    let index = 0;

    const add = (cb: any, ...args: any) => {
      index = (index + 1) % max;
      return q[index].then(() => cb(...args));
    };

    return add;
  };

  useEffect(() => {
    getEnvironment();

    const client = new ApiClient(parameters.secretKey, parameters.authToken, parameters.exportFormat, "prod");

    const createAction = (data: actionData) => {
      return (
        <>
          <TextLink
            alignIcon="start"
            variant="positive"
            icon={<LanguageIcon />}
            onClick={() => {
              OpenUpdateModal(data, sdk).then((newObj) => {
                if (!newObj) return;
                setEntityUpdate(newObj);
              });
            }}
            className="dashboard-link"
          >
            Review File
          </TextLink>
        </>
      );
    };

    const reduceData = (data: IFiles[]) => {
      let resp: ReducedFile[] = [];

      data.forEach((file) => {
        let fileRed = {} as ReducedFile;

        fileRed.id = file.id;
        fileRed.fileName = file.fileName;
        fileRed.wordCount = file.wordCount;
        fileRed.language = file.language;

        let data = file.data;
        if (entityUpdate[file.id]) {
          Object.keys(entityUpdate[file.id]).forEach((key) => {
            data[key] = entityUpdate[file.id][key];
          });
        }

        fileRed.actions = createAction({ data: data, id: file.id, lang: file.language });

        resp.push(fileRed);
      });

      return resp;
    };

    const getDataAsync = async () => {
      const file = await GetData(client, project);
      const reduced = reduceData(file);
      setFilesRow(reduced);
    };

    getDataAsync();
  }, [entityUpdate, parameters.authToken, parameters.exportFormat, parameters.secretKey, project, sdk]);

  return (
    <>
      <div
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ border: "1px solid black", padding: "15px" }}>
          <p>
            <span>Project #:</span> {project.name}
          </p>
        </div>
        <div>
          <div style={{ padding: "5px" }}>
            <DynamicTable headers={header} data={filesRow} />
          </div>
        </div>
      </div>
      <Modal.Controls style={{ position: "fixed", bottom: "1%", right: "1%" }}>
        <Button
          size="small"
          variant="negative"
          style={{
            marginTop: "10px",
            marginBottom: "5px",
            float: "right",
          }}
          onClick={() => sdk.close()}
        >
          Cancel
        </Button>

        <Button
          size="small"
          variant="primary"
          style={{
            marginTop: "10px",
            marginBottom: "5px",
            float: "right",
          }}
          isDisabled={showSpinner}
          onClick={() => ApplyLocal()}
        >
          Apply
        </Button>

        <Button
          size="small"
          variant="positive"
          style={{
            marginTop: "10px",
            marginBottom: "5px",
            float: "right",
          }}
          isDisabled={showSpinner}
          onClick={() => SendUpdateToGPI()}
        >
          Apply & Send to GPI
        </Button>
        <div id="spinner" className={showSpinner ? "" : "hidden-spinner"}>
          <Paragraph
            style={{
              paddingBottom: "5px",
              paddingTop: "10px",
            }}
          >
            <b>Applying Changes</b> <Spinner />
          </Paragraph>
        </div>
      </Modal.Controls>
    </>
  );
};
