import { DialogAppSDK } from "@contentful/app-sdk";
import NewRequest from "./Dialogs/NewRequest";
import { ProjectFilesReview } from "./Dialogs/ProjectFilesReview";
import { TranslationReview } from "./Dialogs/TranslationReview";

interface DialogProps {
  sdk: DialogAppSDK;
}

const Dialog = (props: DialogProps) => {
  const { sdk } = props;

  const invocation = sdk.parameters.invocation as { type: string };
  switch (invocation.type) {
    case "newRequest":
      return <NewRequest sdk={sdk} />;
    case "ProjectFilesReview":
      return <ProjectFilesReview sdk={sdk} />;
    case "TranslationReview":
      return <TranslationReview sdk={sdk} />;

    default:
      return <div>Error</div>;
  }
};

export default Dialog;
