import React from "react";
import {
  Paragraph,
  Button,
  SelectField,
  Option,
  Checkbox,
  FormLabel,
  Notification,
  Spinner,
} from "@contentful/forma-36-react-components";
import { SidebarAppSDK } from "@contentful/app-sdk";
import { ApiClient } from "../gpi-api/apiClient";

interface SidebarProps {
  sdk: SidebarAppSDK;
}

const Sidebar = (props: SidebarProps) => {
  // Gets the Contentful SDK
  const { sdk } = props;

  let systemProps = sdk.entry.getSys();
  let otherLocales: string = "";
  let [sourceLanguage, setSourceLanguage] = React.useState(sdk.locales.available[0]);
  let targetLanguages: string[] = [];
  let [shouldHideButton, setShouldHideButton] = React.useState(false);
  let [shouldHideSpinner, setShouldHideSpinner] = React.useState(true);

  const onSelectSourceLanguage = (langCode: string) => {
    setSourceLanguage(langCode);
  };

  const onSelectTargetLanguage = (checked: boolean, langCode: string) => {
    if (checked) {
      if (targetLanguages.lastIndexOf(langCode) === -1) {
        targetLanguages.push(langCode);
      }
    } else {
      if (targetLanguages.lastIndexOf(langCode) > -1) {
        targetLanguages = targetLanguages.filter((e) => e !== langCode);
      }
    }
  };

  // Translated Into field value setup
  sdk.locales.available.forEach((locale, i) => {
    if (locale === sdk.locales.default) {
      return;
    }

    if (otherLocales !== "") {
      otherLocales += ", ";
    }

    otherLocales += sdk.locales.names[locale];
  });

  if (otherLocales === "") {
    otherLocales = "None";
  }

  const sendForTranslation = () => {
    const id = systemProps.id;
    const contentType = sdk.contentType.name;

    if (!sourceLanguage) {
      Notification.setDuration(2000);
      Notification.error("Please, select a source language.");
      return;
    }

    if (!targetLanguages || targetLanguages.length === 0) {
      Notification.setDuration(2000);
      Notification.error("Please, select at least one target language.");
      return;
    }

    if (
      targetLanguages.filter((lang) => {
        return lang === sourceLanguage;
      }).length > 0
    ) {
      Notification.setDuration(2000);
      Notification.error("The source language can not be selected as a target language.");
      return;
    }

    const fields: any = {};
    for (let field in sdk.entry.fields) {
      let matches = sdk.contentType.fields.filter((locField: any) => {
        return locField.localized && locField.id === field;
      });
      if (matches.length > 0) {
        fields[field] = sdk.entry.fields[field].getValue();
      }
    }

    // Shows the spinner
    setShouldHideButton(true);
    setShouldHideSpinner(false);

    let parameters: any = sdk.parameters.installation;
    const client = new ApiClient(parameters.secretKey, parameters.authToken, parameters.exportFormat, "prod");

    client.sendSingleItemForTranslation(id, contentType, fields, sdk.user, sourceLanguage, targetLanguages).then(() => {
      Notification.success("The content has been submitted for translation successfully.");
      // Shows the button
      setShouldHideButton(false);
      setShouldHideSpinner(true);
    });
  };

  React.useEffect(() => {
    sdk.window.startAutoResizer();
  }, [sdk.window]);

  // Final layout result
  return (
    <div>
      <SelectField
        id="sourceLanguage"
        name="sourceLanguage"
        labelText="From:"
        value={sdk.locales.available[0]}
        onChange={(e) => onSelectSourceLanguage(e.target.value)}
      >
        {Object.keys(sdk.locales.names).map((key) => {
          return (
            <Option value={key} key={"option-" + key}>
              {sdk.locales.names[key]}
            </Option>
          );
        })}
      </SelectField>
      <Paragraph style={{ paddingBottom: "5px", paddingTop: "10px" }}>
        <b>To:</b>
      </Paragraph>
      {Object.keys(sdk.locales.names).map((key) => {
        return (
          <div key={"div-chk-" + key}>
            <Checkbox
              id={"chk-" + key}
              name={"chk-" + key}
              key={"chk-" + key}
              labelText={sdk.locales.names[key]}
              onChange={(e) => onSelectTargetLanguage(e.target.checked, key)}
            />
            <FormLabel className="checkbox-label" htmlFor={"chk-" + key}>
              {sdk.locales.names[key]}
            </FormLabel>
          </div>
        );
      })}
      <Button
        buttonType="primary"
        size="small"
        className={shouldHideButton ? "hidden-spinner" : ""}
        style={{ marginTop: "10px", marginBottom: "5px" }}
        onClick={() => sendForTranslation()}
        type="submit"
      >
        Send for Translation
      </Button>{" "}
      <div id="spinner" className={shouldHideSpinner ? "hidden-spinner" : ""}>
        <Paragraph style={{ paddingBottom: "5px", paddingTop: "10px" }}>
          <b>Sending for translation</b> <Spinner />
        </Paragraph>
      </div>
    </div>
  );
};

export default Sidebar;
