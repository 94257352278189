import { DialogAppSDK, PageAppSDK } from "@contentful/app-sdk";

export const OpenReviewModal = (project: any, sdk: PageAppSDK) => {

  return sdk.dialogs
    .openCurrent({
      title: "Project Files",
      width: "large",
      minHeight: "80vh",
      position: "center",
      parameters: {
        type: "ProjectFilesReview",
        data: project,
      },
    })
    .then((data) => {
      window.location.reload();
    });
};

export const OpenUpdateModal = (data: any, sdk: DialogAppSDK) => {

  return sdk.dialogs
    .openCurrent({
      title: "Project Files",
      width: 800,
      minHeight: "80vh",
      position: "center",
      allowHeightOverflow: false,
      parameters: {
        type: "TranslationReview",
        data: data,
      },
    })
    .then((resp) => {
      if(!resp) return;
      return resp;
    });
};

export const OpenNewRequestModal = (sdk: PageAppSDK) => {
  return sdk.dialogs
    .openCurrent({
      title: "New Request for Quote",
      width: 800,
      minHeight: "80vh",
      allowHeightOverflow: false,
      parameters: {
        type: "newRequest",
      },
    })
    .then((data) => {
      window.location.reload();
    });
};
