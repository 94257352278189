import { Badge, BadgeVariant } from "@contentful/f36-components";

interface BadgeStatusProps {
  status: string;
}

const mapStatusBadge = (quoteStatus: string): BadgeVariant => {
  let res: BadgeVariant = "secondary";

  switch (quoteStatus) {
    case "QuoteOpen":
      res = "primary";
      break;
    case "QuoteInProgress":
      res = "primary";
      break;
    case "QuoteFinished":
      res = "featured";
      break;
    case "ProjectStarted":
      res = "warning";
      break;
    case "ProjectCompleted":
      res = "positive";
      break;
    default:
      res = "secondary";
  }

  return res;
};
const mapStatus = (quoteStatus: string) => {
  let res = "";

  switch (quoteStatus) {
    case "QuoteOpen":
      res = "In Progress";
      break;
    case "QuoteInProgress":
      res = "In Progress";
      break;
    case "QuoteFinished":
      res = "Finished";
      break;
    case "ProjectStarted":
      res = "Started";
      break;
    case "ProjectCompleted":
      res = "Completed";
      break;
    default:
      res = "Unknown";
  }

  return res;
};

export const BadgeStatus = (props: BadgeStatusProps) => {
  const { status } = props;

  return <Badge variant={mapStatusBadge(status)}>{mapStatus(status)}</Badge>;
};
