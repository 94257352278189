import { PageAppSDK } from "@contentful/app-sdk";
import { ModalConfirm, ModalLauncher, Notification, Paragraph } from "@contentful/f36-components";
import { ApiClient } from "../../gpi-api/apiClient";
import { createClient } from "contentful-management";
import { Entry, Environment, Space } from "contentful-management/types";
import { Buffer } from "buffer";
import { htmlToRichText } from "html-to-richtext-contentful";

export const onImport = (quoteId: number, sdk: PageAppSDK) => {
  const cma = createClient({ apiAdapter: sdk.cmaAdapter });

  const getSpace = () => {
    return cma.getSpace(sdk.ids.space);
  };

  const getEnvironment = (space: Space) => {
    return space.getEnvironment(sdk.ids.environment);
  };

  const getContentTypes = (environment: Environment) => {
    return environment.getContentTypes();
  };

  const getEntry = (environment: Environment, id: string) => {
    return environment.getEntry(id);
  };

  const copySourceToTarget = (entry: Entry, sourceLangCode: string, targetLanguageCode: string) => {
    for (const field in entry.fields) {
      let entryTarget = entry.fields[field][sourceLangCode];

      if (
        entryTarget?.sys?.type === "Link" ||
        entryTarget?.sys?.linkType === "Entry" ||
        entryTarget?.sys?.linkType === "Asset" ||
        entryTarget?.nodeType === "document"
      ) {
        continue;
      }

      if (Array.isArray(entryTarget)) {
        entryTarget = entryTarget.filter((item: any) => {
          return !(
            item &&
            item.sys &&
            item.sys.type === "Link" &&
            (item.sys.linkType === "Entry" || item.sys.linkType === "Asset")
          );
        });

        if (entryTarget.length === 0) {
          continue;
        }
      }

      entry.fields[field][targetLanguageCode] = entry.fields[field][sourceLangCode];
    }
  };

  const updateEntry = (entry: Entry, docs: any[], sourceLangCode: string, contentTypes: any) => {
    // promises per document (1 doc per language)
    let perLanguagePromises: any[] = [];

    docs.forEach((doc: any, index: number) => {
      let targetLanguageCode: string = doc.language;
      let translatedString: string = Buffer.from(doc.contents, "base64").toString("utf-8");

      let languagePromise = parseTranslatedDocument(translatedString, doc.fileName).then((translatedJson: any) => {
        // selects the richText fields from the corresponding content type
        let richTextFields: any[] = getRichTextFields(entry, contentTypes);

        // first copies all the properties from the source to the target
        copySourceToTarget(entry, sourceLangCode, targetLanguageCode);

        // then adds the translations
        for (const field in translatedJson) {
          if (field === "_gpiInternalName") {
            continue;
          }

          let translatedValue = translatedJson[field];
          let isRichText =
            richTextFields.filter((rtField: any) => {
              return rtField === field;
            }).length > 0;

          // if the field's type is richText, then converts the HTML to RichText
          if (isRichText) {
            entry.fields[field][targetLanguageCode] = htmlToRichText(translatedValue);
          } else {
            if (
              entry.fields[field][targetLanguageCode]?.sys?.type !== "Link" ||
              entry.fields[field][sourceLangCode]?.sys?.linkType !== "Entry"
            ) {
              entry.fields[field][targetLanguageCode] = translatedValue;
            }
          }
        }
      });

      perLanguagePromises.push(languagePromise);
    });

    // waits for all promises to finalize
    return Promise.all(perLanguagePromises).then(() => {
      entry.update();
    });
  };

  const parseTranslatedDocument: any = (docContent: string, docName: string) => {
    // checks if the document is XLIFF
    if (docName.endsWith(".xlf")) {
      return new Promise(function (resolve, reject) {
        const xliff2js = require("xliff/cjs/xliff2js");
        const targetOfjs = require("xliff/cjs/targetOfjs");
        xliff2js(docContent, (err: any, res: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(targetOfjs(res));
        });
      });
    } else {
      // assumes it's JSON format
      return new Promise(function (resolve, reject) {
        try {
          resolve(JSON.parse(docContent));
        } catch (ex) {
          reject(ex);
        }
      });
    }
  };

  const getRichTextFields = (entry: Entry, contentTypes: any) => {
    let richTextFields: any[] = [];
    if (contentTypes && entry.sys && entry.sys.contentType) {
      contentTypes.items.forEach((type: any, i: number) => {
        // if the content type matches with the entry content type
        if (type.sys.id === entry.sys.contentType.sys.id) {
          type.fields.forEach((field: any, i: number) => {
            // if the field is RichText
            if (field.type === "RichText") {
              richTextFields.push(field.id);
            }
          });
        }
      });
    }

    return richTextFields;
  };

  const importTranslations = (quoteId: number) => {
    let parameters: any = sdk.parameters.installation;
    const client = new ApiClient(parameters.secretKey, parameters.authToken, parameters.exportFormat, "prod");

    const uuid = require("uuid");
    let requestId = uuid.v4();

    return client
      .getQuote(quoteId)
      .then((quote) => {
        return client
          .getDocuments(quoteId, requestId)
          .then((result) => {
            return getSpace()
              .then(getEnvironment)
              .then((environment) => {
                return getContentTypes(environment).then((contentTypes) => {
                  let promises: any[] = [];
                  let documentsMap: any[] = [];

                  result.documents.forEach((doc: any, index: number) => {
                    let docs = documentsMap.filter((d) => {
                      return d.id === doc.id;
                    });
                    if (docs.length > 0) {
                      docs[0].documents.push(doc);
                    } else {
                      documentsMap.push({
                        id: doc.id,
                        documents: [doc],
                      });
                    }
                  });

                  documentsMap.forEach((mapEntry: any, index: number) => {
                    let promise = new Promise((f) => setTimeout(f, index * 100)).then(() => {
                      return getEntry(environment, mapEntry.id).then((entry: Entry) => {
                        if (!entry) {
                          return;
                        }
                        return updateEntry(entry, mapEntry.documents, quote.sourceLanguageISOCode, contentTypes);
                      });
                    });
                    promises.push(promise);
                  });
                  return Promise.all(promises)
                    .then(() => {
                      Notification.success("The translated content has been imported successfully.");
                    })
                    .catch((error) => {
                      Notification.setDuration(2000);
                      Notification.error("An error ocurred while trying to import the translated content.");
                      console.log(error);
                    });
                });
              });
          })
          .catch((error) => {
            Notification.setDuration(2000);
            Notification.error("An error ocurred while trying to get the documents to be imported.");
            console.log(error);
          });
      })
      .catch((error) => {
        Notification.setDuration(2000);
        Notification.error("An error ocurred while trying to get the information of the project.");
        console.log(error);
      });
  };

  return ModalLauncher.open(({ isShown, onClose }) => {
    return (
      <ModalConfirm
        title="Import translation"
        intent="positive"
        isShown={isShown}
        onCancel={() => {
          onClose(false);
        }}
        onConfirm={() => {
          onClose(true);
        }}
        confirmLabel="Continue"
        cancelLabel="Cancel"
      >
        <Paragraph>
          This action will import the translation back into each target language. This action will overlap any existing
          translation on the corresponding target languages.
        </Paragraph>
      </ModalConfirm>
    );
  }).then((result) => {
    if (result === true) {
      importTranslations(quoteId);
    }
  });
};
