import React from "react";
import { Paragraph } from "@contentful/forma-36-react-components";
import { FieldAppSDK } from "@contentful/app-sdk";

interface FieldProps {
  sdk: FieldAppSDK;
}

const Field = (props: FieldProps) => {
  // If you only want to extend Contentful's default editing experience
  // reuse Contentful's editor components
  // -> https://www.contentful.com/developers/docs/extensibility/field-editors/
  return <Paragraph>Hello Entry Field Component</Paragraph>;
};

export default Field;
