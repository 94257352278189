import { PageAppSDK } from "@contentful/app-sdk";
import { ModalConfirm, ModalLauncher, Notification, Paragraph, TextInput } from "@contentful/f36-components";
import { Collection, Entry, EntryProps, Environment, KeyValueMap, Space, createClient } from "contentful-management";

export const CleanFilesAction = (sdk: PageAppSDK) => {
  let contentIDs = "";

  let environmentSDK: Environment;
  let spaceSDK: Space;

  const cma = createClient({
    apiAdapter: sdk.cmaAdapter,
  });

  const getSpace = async (): Promise<Space> => {
    if (spaceSDK) return spaceSDK;

    const resp = await cma.getSpace(sdk.ids.space);
    spaceSDK = resp;
    return resp;
  };

  const getEnvironment = async () => {
    if (environmentSDK) return environmentSDK;

    let space = spaceSDK;

    if (space == null) space = await getSpace();

    const environment = await space.getEnvironment(sdk.ids.environment);
    environmentSDK = environment;

    return environment;
  };

  const getEnvironmentEntries = async (
    skipEntries: number = 0,
  ): Promise<Collection<Entry, EntryProps<KeyValueMap>>> => {
    let query: any = {
      "sys.id[in]": contentIDs.trim(),
      skip: skipEntries,
      limit: 5,
    };

    let environment = environmentSDK;

    if (environment == null) environment = await getEnvironment();

    const resp = await environment.getEntries(query);

    return resp;
  };

  const recursiveDeleteCopyEntity = async (skip: number) => {
    const envi = await getEnvironment();

    const locales = await envi.getLocales();

    let localeInfo = locales.items.map((locale) => {
      return {
        code: locale.code,
        default: locale.default,
        fallbackCode: locale.fallbackCode,
      };
    });

    const localeDefault = localeInfo.find((locale) => locale.default)!.code;

    const entries = await getEnvironmentEntries(skip);

    const promisesUpdate: Promise<any>[] = [];

    entries.items.forEach((entry: Entry) => {
      let notCopyData: any = {};
      Object.keys(entry.fields).forEach((key) => {
        const entryField = entry.fields[key];

        if (!entryField) return;

        if (entryField[localeDefault]) {
          notCopyData[key] = {};

          localeInfo.forEach((localeData: any) => {
            if (!entryField[localeData.code]) {
              return;
            }

            if (localeData.default) {
              notCopyData[key][localeData.code] = entryField[localeData.code];
            }

            if (
              !Array.isArray(entryField[localeData.code]) &&
              !entryField[localeData.code]["sys"] &&
              entryField[localeData.code] !== entryField[localeDefault]
            ) {
              notCopyData[key][localeData.code] = entryField[localeData.code];
            }

            if (
              entryField[localeData.code]["sys"] &&
              entryField[localeData.code]["sys"].type === "Link" &&
              (entryField[localeData.code]["sys"].linkType === "Asset" ||
                entryField[localeData.code]["sys"].linkType === "Entry") &&
              entryField[localeDefault]["sys"].type === "Link" &&
              (entryField[localeDefault]["sys"].linkType === "Asset" ||
                entryField[localeDefault]["sys"].linkType === "Entry") &&
              entryField[localeData.code]["sys"].id !== entryField[localeDefault]["sys"].id
            ) {
              notCopyData[key][localeData.code] = entryField[localeData.code];
            }

            if (Array.isArray(entryField[localeData.code])) {
              const resp = entryField[localeData.code].filter((item: any) => {
                return (
                  item["sys"] &&
                  item["sys"].type === "Link" &&
                  item["sys"].linkType === "Asset" &&
                  entryField[localeDefault].find((itemDefault: any) => {
                    return item["sys"].id === itemDefault["sys"].id;
                  })
                );
              });


              if (resp.length === 0) {
                return;
              }

              notCopyData[key][localeData.code] = resp;
            }
          });
        }
      });

      entry.fields = notCopyData;

      promisesUpdate.push(entry.update());
    });

    await Promise.all(promisesUpdate);

    if (entries.total > skip) {
      skip = skip + entries.limit;
      recursiveDeleteCopyEntity(skip);
    }
  };

  const closeModal = () => {
    return recursiveDeleteCopyEntity(0).then(
      (res) => {
        Notification.success("The cleaner has been executed.");
        // Reloads the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {
        Notification.error("An error ocurred while trying to clean files. Details: " + error);
      },
    );
  };

  return ModalLauncher.open(({ isShown, onClose }) => {
    return (
      <ModalConfirm
        title="Clean Files"
        intent="negative"
        isShown={isShown}
        onCancel={() => {
          onClose(false);
        }}
        onConfirm={() => {
          onClose(true);
        }}
        confirmLabel="Continue"
        cancelLabel="Cancel"
      >
        <Paragraph>
          This action will find the entries in the environment and remove the translations that are the same as the
          default language.
        </Paragraph>
        <Paragraph>Insert the Ids of the entries you want to clean separated by ",".</Paragraph>
        <TextInput
          id="cleanFiles"
          type="text"
          onChange={(e) => (contentIDs = e.target.value)}
          placeholder="insert Ids split by ,"
        />
      </ModalConfirm>
    );
  }).then((result) => {
    if (result === true) {
      closeModal();
    }
  });
};
