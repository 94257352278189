import { DialogAppSDK } from "@contentful/app-sdk";
import { Table, Button } from "@contentful/f36-components";
import { EditableCell } from "../UI/EditableCell";
import { useCallback, useState } from "react";
import { isEmpty } from "../helpers/isEmpty";
import { ModalConfirm, Paragraph } from "@contentful/forma-36-react-components";
import { actionData } from "./ProjectFilesReview";

interface DialogProps {
  sdk: DialogAppSDK;
}

export const TranslationReview = (props: DialogProps) => {
  const { sdk } = props;
  const invocation = sdk.parameters.invocation as { type: string; data: { project: any } };
  const [updateData, setUpdateData] = useState<Record<string, {}>>({});
  const [showModal, setShowModal] = useState<boolean>(false);

  const data = invocation.data as any as actionData;

  const sendData = useCallback(() => {
    let resp = CreateObject(data.id, updateData);
    resp["lang"] = data.lang;
    sdk.close(resp);
  }, [updateData, data, sdk]);

  const sendReview = useCallback(() => {
    if (!updateData || isEmpty(updateData)) {
      setShowModal(true);
      return;
    }
    sendData();
  }, [sendData, updateData]);

  const UpdateRow = useCallback(
    (dataUpdate: any, newLine: string) => {
      if (isEmpty(updateData)) {
        setUpdateData(CreateObject(dataUpdate, newLine));
        return;
      }

      const data = updateData;
      const find = data[dataUpdate];

      if (find) {
        data[dataUpdate] = newLine;
        setUpdateData(data);
        return;
      }

      const newObj = CreateObject(dataUpdate, newLine);
      const upData = { ...data, ...newObj };
      setUpdateData(upData);
    },
    [updateData],
  );

  return (
    <div style={{ height: "90vh" }}>
      <div
        style={{
          width: "95%",
          marginTop: "0.5em",
          marginLeft: "auto",
          marginRight: "auto",
          overflowY: "auto",
        }}
      >
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>Title</Table.Cell>
              <Table.Cell>Translation</Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <>
              {Object.keys(data.data).map((content: any, i) => {
                return (
                  <Table.Row key={content}>
                    <Table.Cell width={20}>{content}</Table.Cell>
                    <Table.Cell width={80}>
                      <EditableCell
                        valueID={content}
                        value={data.data[content]}
                        onChange={(newString: any) => UpdateRow(content, newString)}
                        resetValue={(value: string) => UpdateRow(content, value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </>
          </Table.Body>
        </Table>
        <div style={{ position: "absolute", bottom: "5%", right: "1em" }}>
          <Button
            size="small"
            variant="primary"
            style={{
              margin: "10px",
              float: "right",
            }}
            onClick={() => sendReview()}
          >
            Send For Review
          </Button>
          <Button
            size="small"
            variant="transparent"
            onClick={() => sdk.close()}
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              float: "right",
            }}
          >
            Close
          </Button>
        </div>
      </div>
      <ModalConfirm
        title="Alert"
        intent="positive"
        isShown={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        onConfirm={() => {
          setShowModal(false);
          sdk.close();
        }}
        confirmLabel="Continue"
        cancelLabel="Cancel"
      >
        <Paragraph>No reviews saved, please save at least one review before sending for review.</Paragraph>
      </ModalConfirm>
    </div>
  );
};

const CreateObject = <K extends string, T extends {}>(keyName: K, details: T) => {
  return { [keyName]: details } as Record<K, T |string >;
};
