import { PageAppSDK } from "@contentful/app-sdk";
import { ModalConfirm, ModalLauncher, Notification, Paragraph } from "@contentful/f36-components";
import { ApiClient } from "../../gpi-api/apiClient";

export const onArchive = (quoteId: number, sdk: PageAppSDK) => {
  const closeProject = (quoteId: number) => {
    const parameters: any = sdk.parameters.installation;
    const client = new ApiClient(parameters.secretKey, parameters.authToken, parameters.exportFormat, "prod");

    return client.closeProject(quoteId).then(
      (res) => {
        Notification.success("The project has been archived successfully.");
        // Reloads the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {
        Notification.error("An error ocurred while trying to get the information of the project. Details: " + error);
      },
    );
  };

  return ModalLauncher.open(({ isShown, onClose }) => {
    return (
      <ModalConfirm
        title="Archive project"
        intent="negative"
        isShown={isShown}
        onCancel={() => {
          onClose(false);
        }}
        onConfirm={() => {
          onClose(true);
        }}
        confirmLabel="Continue"
        cancelLabel="Cancel"
      >
        <Paragraph>
          If you archive this project, it won't be visible again. You will still be able to access it through the GPI
          Translation Portal.
        </Paragraph>
      </ModalConfirm>
    );
  }).then((result) => {
    if (result === true) {
      closeProject(quoteId);
    }
  });
};
