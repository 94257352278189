import { useEffect, useState } from "react";
import { Heading, Button, Notification, TextLink, Note } from "@contentful/f36-components";
import {
  ExternalLinkIcon,
  PlusCircleIcon,
  LanguageIcon,
  FolderOpenIcon,
  EnvironmentAliasIcon,
} from "@contentful/f36-icons";
import { PageAppSDK } from "@contentful/app-sdk";
import { ApiClient } from "../gpi-api/apiClient";
import logo from "../logo.png";
import DynamicTable from "./UI/DynamicTable";
import { BadgeStatus } from "./UI/BadgeStatus";
import { OpenNewRequestModal } from "./ModalActions/OpenModals";
import { onImport } from "./ModalActions/OnImport";
import { onArchive } from "./ModalActions/OnArchive";
import { CleanFilesAction } from "./ModalActions/CleanFilesAction";

interface PageProps {
  sdk: PageAppSDK;
}

interface ProjectTablesProps {
  Id: number;
  Name: string;
  Status: JSX.Element;
  CreatedBy: string;
  CreatedOn: string;
  Source: string;
  Target: string;
  Actions: JSX.Element;
}

const Page = (props: PageProps) => {
  // Gets the Contentful SDK
  const { sdk } = props;

  const [quotes, setQuotes] = useState<ProjectTablesProps[]>([]);
  const [projects, setProjects] = useState<ProjectTablesProps[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const spacesIdsAllowed = ['m9mcybwguwgq','2yuyw88gxocw',]

  const headers = [
    { Name: "Name", Field: "Name" },
    { Name: "Status", Field: "Status" },
    { Name: "Created By", Field: "CreatedBy" },
    { Name: "Created On", Field: "CreatedOn" },
    { Name: "Source", Field: "Source" },
    { Name: "Target", Field: "Target" },
    { Name: "Actions", Field: "Actions", Width: "15%" },
  ];

  useEffect(() => {
    const createStatus = (status: string) => {
      return <BadgeStatus status={status} />;
    };

    const createAction = (project: any) => {
      return (
        <>
          {project.portalLink && (
            <TextLink
              icon={<ExternalLinkIcon />}
              alignIcon="start"
              style={{ margin: "2px" }}
              href={project.portalLink}
              target="_blank"
              rel="noopener noreferrer"
              className="dashboard-link"
            >
              View Status Report
            </TextLink>
          )}
          {project.status === "ProjectCompleted" && (
            <TextLink
              alignIcon="start"
              variant="positive"
              style={{ margin: "2px" }}
              icon={<LanguageIcon />}
              onClick={() => onImport(project.id, sdk)}
              className="dashboard-link"
            >
              Import
            </TextLink>
          )}
          {project.status === "ProjectCompleted" && (
            <TextLink
              alignIcon="start"
              variant="negative"
              style={{ margin: "2px" }}
              icon={<FolderOpenIcon />}
              onClick={() => onArchive(project.id, sdk)}
              className="dashboard-link"
            >
              Archive
            </TextLink>
          )}
          {/* {project.portalLink && project.status === "ProjectCompleted" && (
            <TextLink
              icon={<PreviewIcon />}
              alignIcon="start"
              style={{ color: "black", margin: "2px" }}
              onClick={() => OpenReviewModal(project, sdk)}
              className="dashboard-link"
            >
              Review
            </TextLink>
          )} */}
        </>
      );
    };

    const reduceData = (resQuotes: any) => {
      const resp: ProjectTablesProps[] = [];
      resQuotes.forEach((element: any) => {
        let quote = {} as ProjectTablesProps;

        quote.Name = element.name;
        quote.Status = createStatus(element.status);
        quote.CreatedBy = element.createdBy as string;
        quote.CreatedOn = `${new Date(element.lastModifiedOn).toLocaleDateString()} ${new Date(
          element.lastModifiedOn,
        ).toLocaleTimeString()}`;
        quote.Source = element.sourceLanguage;
        quote.Target = element.targetLanguages.join(", ");
        quote.Actions = createAction(element);

        resp.push(quote);
      });

      return resp;
    };

    const getQuotes = () => {
      const parameters: any = sdk.parameters.installation;
      const client = new ApiClient(parameters.secretKey, parameters.authToken, parameters.exportFormat, "prod");

      return client.getQuotes().then(
        (result) => {
          const resQuotes = result.filter((res: any) => {
            return !res.status.includes("QuoteOpen") && !res.status.includes("Project");
          });
          const resProjects = result.filter((res: any) => {
            return !res.status.includes("QuoteOpen") && res.status.includes("Project");
          });

          const quotesReduced = reduceData(resQuotes);
          const projectsReduced = reduceData(resProjects);

          setQuotes(quotesReduced);
          setProjects(projectsReduced);

          setDataLoaded(true);
        },
        (error) => {
          Notification.error("An error ocurred while getting projects information. Details: " + error);
        },
      );
    };

    getQuotes();
  }, [sdk, sdk.parameters.installation]);

  return (
    <div style={{ width: "95%", marginLeft: "auto", marginRight: "auto", marginTop: "10px" }}>
      <a href="https://www.globalizationpartners.com/" target="_blank" rel="noreferrer">
        <img src={logo} style={{ width: "400px" }} alt={"gpi logo"} />
      </a>
      <hr />
      <div style={{ marginTop: "20px" }}>
        <Button
          variant="positive"
          size="small"
          style={{ float: "right" }}
          endIcon={<PlusCircleIcon />}
          onClick={() => OpenNewRequestModal(sdk)}
        >
          New Request
        </Button>
        {
          sdk.ids.space && spacesIdsAllowed.includes(sdk.ids.space) &&

          <Button
            variant="secondary"
            size="small"
            style={{ float: "right", marginRight: "10px" }}
            endIcon={<EnvironmentAliasIcon />}
            onClick={() => CleanFilesAction(sdk)}
          >
            Clean translations
          </Button>
        }
        <Heading style={{ fontSize: "32px" }}>Quotes</Heading>
        <DynamicTable data={quotes} headers={headers} style={{ marginTop: "15px" }} />
        {dataLoaded && quotes.length === 0 && <Note style={{ marginTop: "15px" }}>There are no quotes to show.</Note>}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Heading style={{ fontSize: "32px" }}>Projects</Heading>
        <DynamicTable data={projects} headers={headers} style={{ marginTop: "15px" }} />

        {dataLoaded && projects.length === 0 && (
          <Note style={{ marginTop: "15px" }}>There are no translation projects to show.</Note>
        )}
      </div>
    </div>
  );
};

export default Page;
