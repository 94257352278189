import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  SelectField,
  Option,
  Paragraph,
  Checkbox,
  FormLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Notification,
  Textarea,
  Tabs,
  Tab,
  TabPanel,
  TextInput,
  Spinner,
  Tag,
  SkeletonRow,
  Note,
  CheckboxField,
} from "@contentful/forma-36-react-components";
import { DialogAppSDK } from "@contentful/app-sdk";
import { createClient } from "contentful-management";

import { Environment, Space } from "contentful-management/types";
import { Options, documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { ApiClient } from "../../gpi-api/apiClient";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

interface DialogProps {
  sdk: DialogAppSDK;
}

const NewRequest = (props: DialogProps) => {
  // Gets the Contentful SDK
  const { sdk } = props;
  const cma = createClient({
    apiAdapter: sdk.cmaAdapter,
  });

  let [sourceLanguage, setSourceLanguage] = React.useState(sdk.locales.available[0]);
  let [targetLanguages, setTargetLanguages] = React.useState<string[]>([]);
  let [title, setTitle] = React.useState("");
  let [comments, setComments] = React.useState("");
  let [exportReferencedEntries, setExportReferencedEntries] = React.useState(true);
  let [selectedTab, setSelectedTab] = React.useState("gpi-info-tab");
  let [shouldHideButton, setShouldHideButton] = React.useState(false);
  let [shouldHideSpinner, setShouldHideSpinner] = React.useState(true);
  let [allEntries, setAllEntries] = React.useState<any[]>([]);
  let [entries, setEntries] = React.useState<any[]>([]);
  let [environment, setEnvironment] = React.useState<any>();
  let [contentTypes, setContentTypes] = React.useState([]);
  let [dataLoaded, setDataLoaded] = React.useState(false);
  const [checks, setChecks] = useState<any[]>([]);
  // The connector will ignore exporting related entries from the following types
  let ignoreRelatedEntriesContentTypes = ["lodge", "page", "pages", "promotions", "skill"];

  const getSpace = useCallback(() => {
    return cma.getSpace(sdk.ids.space);
  }, [cma, sdk.ids.space]);

  const getEnvironment = useCallback(
    (space: Space) => {
      return space.getEnvironment(sdk.ids.environment);
    },
    [sdk.ids.environment],
  );

  const OptionsRichtext = useMemo(() => {
    const options: Partial<Options> = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
          const customTag = `<embedded-entry id="${node.data.target.sys.id}" ></embedded-entry>`;
          return customTag;
        },
        [INLINES.EMBEDDED_ENTRY]: (node, children) => {
          const customTagHtml = `<inline-entry id="${node.data.target.sys.id}"></inline-entry>`;
          return customTagHtml;
        },
      },
      preserveWhitespace: true,
    };

    return options;
  }, []);

  const getContentTypes = useCallback((environment: Environment) => {
    return environment.getContentTypes();
  }, []);

  const addEnvironmentEntries = useCallback(
    (envEntries: any, contentType: any) => {
      let existing: any = allEntries.filter((e: any) => e.contentType === contentType.sys.id);

      if (existing && existing[0] && existing[0].entries.length > 0) {
        let existingEntries: any[] = existing[0].entries;
        let newEntries: any[] = envEntries.items;
        let combinedEntries: any[] = existingEntries.concat(newEntries);

        let updatedEntries = allEntries.map((item: any) => {
          if (item.contentType === contentType.sys.id) {
            item.entries = combinedEntries;
          }
          return item;
        });

        setAllEntries(updatedEntries);
        setEntries(combinedEntries);
      } else {
        let newItem: any = {
          contentType: contentType.sys.id,
          entries: envEntries.items,
        };

        allEntries.push(newItem);
        setAllEntries(allEntries);
        setEntries(envEntries.items);
      }
    },
    [allEntries],
  );

  const setContentTypeFields = useCallback(
    (entry: any, contentType: any) => {
      let displayField: string = "";
      let displayType: string = "";
      let typeInternalName: string = "";
      let localizedFields: string[] = [];
      let richTextFields: string[] = [];

      if (contentType) {
        displayField = contentType.displayField;
        displayType = contentType.name;
        typeInternalName = contentType.sys.id;

        contentType.fields.forEach((field: any) => {
          // checks localized fields
          if (field.localized) {
            localizedFields.push(field.id);
          }
          // checks richtext fields
          if (field.type === "RichText") {
            richTextFields.push(field.id);
          }
        });
      }

      // Display field
      if (displayField && entry.fields && entry.fields[displayField]) {
        entry.displayTitle = entry.fields[displayField][sdk.locales.default];
      } else {
        entry.displayTitle = "Untitled - ID: " + entry.sys.id;
      }

      // Display type
      if (displayType) {
        entry.displayType = displayType;
      } else {
        entry.displayType = entry.sys.type;
      }

      // Type Internal Name
      if (typeInternalName) {
        entry.typeInternalName = typeInternalName;
      } else {
        entry.typeInternalName = displayType;
      }

      // Localized fields
      if (localizedFields) {
        entry.localizedFields = localizedFields;
      }

      // RichText fields
      if (richTextFields) {
        entry.richTextFields = richTextFields;
      }

      return displayField;
    },
    [sdk.locales.default],
  );

  const getEnvironmentEntries = useCallback(
    (environment: Environment, contentType: any, skipEntries: number): any => {
      let query: any = {
        skip: skipEntries,
      };

      if (contentType) {
        query.content_type = contentType.sys.id;
      }

      return environment
        .getEntries(query)
        .then((resEntries) => {
          resEntries.items = resEntries.items.filter((entry: any) => {
            return !isEmptyObject(entry) && !isEmptyObject(entry.fields);
          });

          console.log(resEntries);
          resEntries.items.forEach((entry: any) => {
            setContentTypeFields(entry, contentType);
          });

          addEnvironmentEntries(resEntries, contentType);

          if (resEntries.total > resEntries.skip + resEntries.limit) {
            return getEnvironmentEntries(environment, contentType, resEntries.skip + resEntries.limit);
          }
        })
        .catch((error: any) => {
          console.log(error.message);
          setDataLoaded(true);
        });
    },
    [addEnvironmentEntries, setContentTypeFields],
  );

  const addContentTypes = (contentTypes: any) => {
    setContentTypes(contentTypes.items);
    return contentTypes.items;
  };

  const onSelectSourceLanguage = (langCode: string) => {
    setSourceLanguage(langCode);
  };

  const onSelectTargetLanguage = (checked: boolean, langCode: string) => {
    let targetLanguagesTemp = targetLanguages;
    if (checked) {
      if (targetLanguagesTemp.lastIndexOf(langCode) === -1) {
        targetLanguagesTemp.push(langCode);
      }
    } else {
      if (targetLanguagesTemp.lastIndexOf(langCode) > -1) {
        targetLanguagesTemp = targetLanguages.filter((e) => e !== langCode);
      }
    }
    setTargetLanguages(targetLanguagesTemp);
  };

  const onSelectContentItem = (checked: boolean, item: any) => {
    item.checked = checked;

    if (checked) {
      setChecks([...checks, item.sys.id]);
    } else {
      setChecks(checks.filter((id: any) => id !== item.sys.id));
    }
  };

  const isChecked = (id: any): boolean => {
    let res = false;

    const finded = checks.find((item: any) => item === id);

    res = !!finded;

    return res;
  };

  const onSelectAllEntries = (checked: boolean) => {
    entries.forEach((item: any) => {
      item.checked = checked;
    });
    setEntries(entries);

    let entryId = [];
    if (checked) {
      entryId = entries.map((item: any) => {
        return item.sys.id;
      });
    }
    setChecks(entryId);
  };

  const onSelectContentTypeFilter = (contentTypeId: string) => {
    let filteredEntries = allEntries.filter((item: any) => {
      return item.contentType === contentTypeId;
    });

    if (filteredEntries.length > 0) {
      setEntries(filteredEntries[0].entries);
    } else {
      const matchingContentTypes: any = contentTypes.filter((c: any) => c.sys.id === contentTypeId);
      if (matchingContentTypes && matchingContentTypes.length > 0) {
        setEntries([]);
        setDataLoaded(false);
        getEnvironmentEntries(environment, matchingContentTypes[0], 0).then(() => {
          setDataLoaded(true);
        });
      }
    }
  };

  const onChangeTitle = (newTitle: string) => {
    setTitle(newTitle);
  };

  const onChangeComments = (newComments: string) => {
    setComments(newComments);
  };

  const isEmptyObject = (obj: any) => {
    for (let prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  };

  const getRichtTextEmbeddedEntries = (fieldValue: any, entriesToFetch: any[]) => {
    if (fieldValue.content && Array.isArray(fieldValue.content)) {
      fieldValue.content.forEach((contentEntry: any) => {
        if (
          contentEntry.data &&
          contentEntry.data.target &&
          contentEntry.nodeType &&
          contentEntry.nodeType.includes("embedded-entry")
        ) {
          contentEntry.data.target.embedded = true;
          entriesToFetch.push(contentEntry.data.target);
        }

        if (contentEntry.content && Array.isArray(contentEntry.content)) {
          getRichtTextEmbeddedEntries(contentEntry, entriesToFetch);
        }
      });
    }
  };

  const getRelatedEntries = (entry: any) => {
    let relatedEntriesIds: any[] = [];

    for (let field in entry.fields) {
      // If the given field does NOT match with any localized field, then continues to the next field
      // this was commented for ZOGO finance, as they requested that references should be exported
      // even though the field is not marked as localized field
      //let matches = entry.localizedFields.filter((locField:any) => { return locField === field});
      //if(matches.length === 0) {
      //continue;
      //}

      // Gets the field value
      let fieldValue = entry.fields[field][sourceLanguage];

      // Parses the references and embedded entries
      let entriesToFetch: any[] = [];
      if (Array.isArray(fieldValue)) {
        entriesToFetch = fieldValue;

        entriesToFetch.forEach((arrEntry: any) => {
          if (arrEntry.content && Array.isArray(arrEntry.content)) {
            // if the field is rich text, it will grab embedded entries
            getRichtTextEmbeddedEntries(arrEntry, entriesToFetch);
          }
        });
      } else {
        entriesToFetch.push(fieldValue);
        // if the field is rich text, it will grab embedded entries
        if (fieldValue) {
          getRichtTextEmbeddedEntries(fieldValue, entriesToFetch);
        }
      }

      // For each referenced entry
      entriesToFetch.forEach((entryToFech) => {
        // If the field is not a reference field, then it continues to the next field
        if (
          !entryToFech ||
          !entryToFech.sys ||
          entryToFech.sys.type !== "Link" ||
          entryToFech.sys.linkType !== "Entry"
        ) {
          return;
        }

        // if it arrives at this point, that means that the field is marked as translatable and
        // has a reference to another item. Then, gets the related entry from Contentful DB.

        // Checks if the entry was not already added to the list.
        let alreadyRetrieved = false;
        relatedEntriesIds.forEach((entryId: any) => {
          if (entryId.id === entryToFech.sys.id) {
            alreadyRetrieved = true;
          }
        });
        // If the entry was not added yet, adds it to the list
        if (!alreadyRetrieved) {
          relatedEntriesIds.push({
            id: entryToFech.sys.id,
            embedded: entryToFech.embedded,
          });
        }
      });
    }

    let relatedEntries: any[] = [];

    // If there are no related entries to fetch
    if (relatedEntriesIds.length === 0) {
      return new Promise((resolve) => {
        resolve(relatedEntries);
      });
    }

    // Creates the list of IDs to query the API
    let idsList = "";
    relatedEntriesIds.forEach((entryId: any) => {
      if (idsList === "") {
        idsList += entryId.id;
      } else {
        idsList += "," + entryId.id;
      }
    });

    // Gets all the related entries via the API
    return environment
      .getEntries({
        "sys.id[in]": idsList,
      })
      .then((res: any) => {
        if (res && res.items) {
          res.items.forEach((related: any) => {
            if (
              related &&
              !relatedEntries.some((r: any) => {
                return r.sys.id === related.sys.id;
              })
            ) {
              let contentType = contentTypes.filter((c: any) => {
                return c.sys.id === related.sys.contentType.sys.id;
              });
              setContentTypeFields(related, contentType[0]);
              relatedEntries.push(related);
            }
          });
        }

        return relatedEntries;
      });
  };

  const getRelatedEntriesRecursive = (entry: any, relatedEntries: any[], level: number) => {
    let promises: Promise<any>[] = [];

    return getRelatedEntries(entry).then((res: any) => {
      let index = 1;
      const queue = multiQueue(6); // most browsers I think limit to 6 simultaneous AJAX anyway

      res.forEach((related: any) => {
        if (
          !relatedEntries.some((r: any) => {
            return r.sys.id === related.sys.id;
          })
        ) {
          /*var isPublished = related.sys && related.sys.publishedAt;*/
          const shouldIgnoreType = ignoreRelatedEntriesContentTypes.some((t: string) => {
            return t === related.typeInternalName;
          });

          if (!shouldIgnoreType /* && isPublished*/) {
            relatedEntries.push(related);

            let promise = queue(() =>
              setDelay(level * index * 100).then(() => {
                return getRelatedEntriesRecursive(related, relatedEntries, level + 1);
              }),
            );

            promises.push(promise);

            index++;
          }
        }
      });

      return Promise.all(promises).then((results) => {
        results.forEach((res) => {
          res.forEach((related: any) => {
            if (
              !relatedEntries.some((r: any) => {
                return r.sys.id === related.sys.id;
              })
            ) {
              relatedEntries.push(related);
            }
          });
        });

        return relatedEntries;
      });
    });
  };

  const getSelectedEntries = (): any => {
    let res: any[] = [];
    let promises: Promise<any>[] = [];
    const queue = multiQueue(6); // most browsers I think limit to 6 simultaneous AJAX anyway

    allEntries.forEach((item: any) => {
      item.entries
        .filter((entry: any): any[] => {
          return entry.checked;
        })
        .forEach((entry: any) => {
          res.push(entry);
          if (exportReferencedEntries) {
            let promise: Promise<any> = queue(() => getRelatedEntriesRecursive(entry, res, 1));
            promises.push(promise);
          }
        });
    });

    return Promise.all(promises).then((results) => {
      results.forEach((relatedEntries) => {
        relatedEntries.forEach((related: any) => {
          if (
            !res.some((r: any) => {
              return r.sys.id === related.sys.id;
            })
          ) {
            res.push(related);
          }
        });
      });
      return res;
    });
  };

  const multiQueue = (max: number) => {
    max = isNaN(max) || max < 1 ? 1 : max;

    const q = new Array(max).fill(0).map(() => Promise.resolve());

    let index = 0;

    const add = (cb: any, ...args: any) => {
      index = (index + 1) % max;
      return (q[index] = q[index].then(() => cb(...args)));
    };

    return add;
  };

  const setDelay = (totalInMs: number) => {
    return new Promise((f) => setTimeout(f, totalInMs));
  };

  const prepareSelectedItemsForTranslation = (): any => {
    return getSelectedEntries().then((selectedEntries: any) => {
      return selectedEntries.map((entry: any) => {
        // Gets the content type
        let contentTypeFiltered = contentTypes.filter((c: any) => {
          return c.sys.id === entry.sys.contentType.sys.id;
        });

        // Sets the fields
        let fields: any = {};
        for (let field in entry.fields) {
          if (!entry.localizedFields) {
            continue;
          }

          let matches = entry.localizedFields.filter((locField: any) => {
            return locField === field;
          });
          if (matches.length > 0) {
            // sets the value
            let fieldValue = entry.fields[field][sourceLanguage];

            if (Array.isArray(fieldValue)) {
              fieldValue = fieldValue.filter((item: any) => {
                return !(
                  item &&
                  item.sys &&
                  item.sys.type === "Link" &&
                  (item.sys.linkType === "Entry" || item.sys.linkType === "Asset")
                );
              });

              if (fieldValue.length === 0) {
                continue;
              }
            }

            if (
              entry.fields[field][sourceLanguage] &&
              entry.fields[field][sourceLanguage].sys &&
              entry.fields[field][sourceLanguage].sys.type === "Link" &&
              (entry.fields[field][sourceLanguage].sys.linkType === "Entry" ||
                entry.fields[field][sourceLanguage].sys.linkType === "Asset")
            ) {
              continue;
            }

            if (entry.richTextFields) {
              let isRichText = entry.richTextFields.filter((rtField: any) => {
                return rtField === field;
              });
              if (isRichText.length > 0) {
                fieldValue = documentToHtmlString(fieldValue, OptionsRichtext);
              }
            }

            // Checks if field is disabled
            let isDisabled = false;
            if (contentTypeFiltered.length > 0 && contentTypeFiltered[0]) {
              let entryContentType: any = contentTypeFiltered[0];
              let currentFieldDefinition = entryContentType.fields.filter((f: any) => {
                return f.id === field;
              });
              isDisabled = currentFieldDefinition.length > 0 && currentFieldDefinition[0].disabled;
            }

            // If the field is not disabled, adds it to the export
            if (!isDisabled) {
              fields[field] = fieldValue;
            }
          }
        }

        // Sets the displayTitle
        let displayTitle = entry.sys.id;

        if (contentTypeFiltered.length > 0) {
          let entryContentType: any = contentTypeFiltered[0];
          let displayField: string = entryContentType.displayField;

          if (displayField && entry.fields && entry.fields[displayField]) {
            displayTitle = entry.fields[displayField][sdk.locales.default];
          }
        }

        // Returns the formatted item for export
        return {
          id: entry.sys.id,
          contentType: entry.typeInternalName,
          fields: fields,
          displayTitle: displayTitle,
        };
      });
    });
  };

  const sendForTranslation = () => {
    // Validations
    if (!title || !title.trim()) {
      Notification.setDuration(2000);
      Notification.error("Please, specify a title for the request.");
      return;
    }

    if (!sourceLanguage) {
      Notification.setDuration(2000);
      Notification.error("Please, select a source language.");
      return;
    }

    if (!targetLanguages || targetLanguages.length === 0) {
      Notification.setDuration(2000);
      Notification.error("Please, select at least one target language.");
      return;
    }

    if (
      targetLanguages.filter((lang) => {
        return lang === sourceLanguage;
      }).length > 0
    ) {
      Notification.setDuration(2000);
      Notification.error("The source language can not be selected as a target language.");
      return;
    }

    // Shows the spinner
    setShouldHideButton(true);
    setShouldHideSpinner(false);

    // Formats the selected items
    return prepareSelectedItemsForTranslation().then((itemsForTranslation: any[]) => {
      if (!itemsForTranslation || itemsForTranslation.length === 0) {
        Notification.setDuration(2000);
        Notification.error("Please, select at least one content item.");
        return;
      }

      // Removes empty entries
      itemsForTranslation = itemsForTranslation.filter((entry: any) => {
        return !isEmpty(entry.fields);
      });

      // Sends a request through the API
      let parameters: any = sdk.parameters.installation;
      const client = new ApiClient(parameters.secretKey, parameters.authToken, parameters.exportFormat, "prod");
      client
        .sendMultipleItemsForTranslation(
          title,
          comments,
          itemsForTranslation,
          sdk.user,
          sourceLanguage,
          targetLanguages,
        )
        .then(
          () => {
            Notification.setDuration(2000);
            Notification.success("The content has been submitted for translation successfully.");
            // Hides the dialog
            setTimeout(() => {
              sdk.close();
            }, 2000);
          },
          (error: string) => {
            Notification.setDuration(0);
            Notification.error(
              "An error ocurred while trying to export the content for translation. Details: " + error,
            );
          },
        );
    });
  };

  const isEmpty = (entry: any) => {
    for (let prop in entry) {
      if (Object.prototype.hasOwnProperty.call(entry, prop)) {
        return false;
      }
    }

    return JSON.stringify(entry) === JSON.stringify({});
  };

  useEffect(() => {
    const getEntries = () => {
      return getSpace()
        .then(getEnvironment)
        .then((environment) => {
          setEnvironment(environment);
          return getContentTypes(environment)
            .then(addContentTypes)
            .then((contentTypes) => {
              let defaultContentType: any = undefined!;
              if (contentTypes && contentTypes.length > 0) {
                defaultContentType = contentTypes[0];
              }
              setDataLoaded(false);
              return getEnvironmentEntries(environment, defaultContentType, 0).then(() => {
                setDataLoaded(true);
              });
            });
        });
    };

    getEntries();
  }, []);

  return (

      <div
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ height: "520px" }}>
          <Tabs role="tablist">
            <Tab
              id="gpi-info-tab"
              selected={selectedTab === "gpi-info-tab"}
              onSelect={() => {
                setSelectedTab("gpi-info-tab");
              }}
            >
              Information
            </Tab>
            <Tab
              id="gpi-content-tab"
              selected={selectedTab === "gpi-content-tab"}
              onSelect={() => {
                setSelectedTab("gpi-content-tab");
              }}
            >
              Content
            </Tab>
          </Tabs>
          <TabPanel
            id="gpi-info-tab"
            style={{
              display: selectedTab === "gpi-info-tab" ? "block" : "none",
            }}
          >
            <div className="f36-margin-bottom--m">
              <Paragraph className="f36-margin-bottom--m f36-margin-top--m">
                <b>Title:</b>
              </Paragraph>
              <TextInput name="quote-title" type="text" value="" onChange={(e) => onChangeTitle(e.target.value)} />
            </div>
            <div className="f36-margin-bottom--m">
              <Paragraph>
                <b>Source Language:</b>
              </Paragraph>
              <SelectField
                id="source-language"
                className="f36-margin-bottom--m"
                name="source-language"
                labelText=""
                value={sdk.locales.available[0]}
                onChange={(e) => onSelectSourceLanguage(e.target.value)}
              >
                {Object.keys(sdk.locales.names).map((key) => {
                  return (
                    <Option value={key} key={"option-" + key}>
                      {sdk.locales.names[key]}
                    </Option>
                  );
                })}
              </SelectField>
            </div>
            <div className="f36-margin-bottom--m">
              <Paragraph>
                <b>Target Languages:</b>
              </Paragraph>
              <div
                style={{
                  maxHeight: "120px",
                  minHeight: "60px",
                }}
                className="f36-margin-top--m"
              >
                {Object.keys(sdk.locales.names).map((key) => {
                  return (
                    <div
                      key={"div-chk-" + key}
                      style={{
                        display: "inline-block",
                        marginRight: "20px",
                      }}
                    >
                      <Checkbox
                        id={"chk-" + key}
                        name={"chk-" + key}
                        key={"chk-" + key}
                        labelText={sdk.locales.names[key]}
                        onChange={(e) => onSelectTargetLanguage(e.target.checked, key)}
                      />
                      <FormLabel className="checkbox-label" htmlFor={"chk-" + key}>
                        {sdk.locales.names[key]}
                      </FormLabel>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="f36-margin-bottom--m">
              <Paragraph>
                <b>Comments:</b>
              </Paragraph>
              <Textarea
                name="notes"
                id="textarea-notes"
                className="f36-margin-top--m"
                rows={3}
                onChange={(e) => onChangeComments(e.target.value)}
              />
            </div>
          </TabPanel>
          <TabPanel
            id="gpi-content-tab"
            style={{
              display: selectedTab === "gpi-content-tab" ? "block" : "none",
            }}
          >
            <div
              style={{
                width: "48%",
                display: "inline-block",
              }}
            >
              <SelectField
                id="contentTypeFilter"
                name="contentTypeFilter"
                labelText="Type:"
                onChange={(e) => onSelectContentTypeFilter(e.target.value)}
              >
                {contentTypes.map((type: any) => {
                  return (
                    <Option value={type.sys.id} key={"option-" + type.name}>
                      {type.name}
                    </Option>
                  );
                })}
              </SelectField>
            </div>
            <div
              style={{
                width: "4%",
                display: "inline-block",
              }}
            ></div>
            {/* <div style={{
                width: "48%",
                display: "inline-block",
              }}
            >
              <FormLabel htmlFor="filter-entry-title">Export Referenced Entries:</FormLabel>
              <div style={{ maxHeight: "120px", minHeight: "60px" }} className="f36-margin-top--m">
                <div style={{ display: "inline-block", marginRight: "20px" }}>
                  <RadioButtonField
                    name="rb-export-related"
                    id="rb-export-related"
                    value="yes"
                    checked={true}
                    onChange={(e) => onSelectExportReferencedEntries(e.target.checked)}
                    labelText="JSON"
                  />
                </div>
                <div style={{ display: "inline-block", marginRight: "20px" }}>
                  <RadioButtonField
                    name="rb-export-related"
                    id="rb-export-related"
                    value="no"
                    onChange={(e) => onSelectExportReferencedEntries(e.target.checked)}
                    labelText="No"
                  />
                </div>
              </div>
            </div> */}
            <div style={{ width: "25%", display: "inline-block" }}>
              <CheckboxField
                name="ckbx-export-related-item"
                id="ckbx-export-related-item"
                labelText="Export related items"
                onChange={(e) => setExportReferencedEntries(!exportReferencedEntries)}
                checked={exportReferencedEntries}
              ></CheckboxField>
            </div>
            <div style={{ marginTop: "15px", height: "370px", overflowY: "scroll" }}>
              <Table
                style={{
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    {/*<TableCell style={{ width: '20%' }}>Type</TableCell>*/}
                    <TableCell
                      style={{
                        width: "15%",
                      }}
                    >
                      Select All
                      <Checkbox
                        id={"chk-SelectAll"}
                        name={"chk-SelectAll"}
                        key={"chk-SelectAll"}
                        labelText={"text"}
                        checked={entries.length > 0 && entries.every((entry: any) => entry.checked)}
                        onChange={(e) => {
                          onSelectAllEntries(e.target.checked);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {dataLoaded &&
                      entries.map((entry: any) => {
                        return (
                          <TableRow key={"t-row-" + entry.sys.id}>
                            <TableCell>
                              {entry.displayTitle}{" "}
                              <Tag tagType={entry.sys.publishedAt ? "positive" : "warning"}>
                                {entry.sys.publishedAt ? "PUBLISHED" : "DRAFT"}
                              </Tag>
                            </TableCell>
                            {/*<TableCell>{entry.displayType}</TableCell>*/}
                            <TableCell>
                              <Checkbox
                                id={"chk-entries-" + entry.sys.id}
                                name={"chk-entries-" + entry.sys.id}
                                key={"chk-entries-" + entry.sys.id}
                                labelText={entry.sys.id}
                                checked={isChecked(entry.sys.id)}
                                onChange={(e) => onSelectContentItem(e.target.checked, entry)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </>
                  <>{!dataLoaded && <SkeletonRow rowCount={5} columnCount={2} />}</>
                </TableBody>
              </Table>
              <>
                {dataLoaded && entries.length === 0 && (
                  <Note
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    There are no entries for the specified filters.
                  </Note>
                )}
              </>
            </div>
          </TabPanel>
        </div>
        <div>
          <Button
            buttonType="primary"
            size="small"
            className={shouldHideButton ? "hidden-spinner" : ""}
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              float: "right",
            }}
            onClick={() => sendForTranslation()}
            type="submit"
          >
            Send for Translation
          </Button>{" "}
          <div id="spinner" className={shouldHideSpinner ? "hidden-spinner" : ""}>
            <Paragraph
              style={{
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
            >
              <b>Sending for translation</b> <Spinner />
            </Paragraph>
          </div>
        </div>
      </div>
  );
};

export default NewRequest;
