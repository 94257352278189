import { SkeletonRow, Table } from "@contentful/f36-components";

export interface IHeader {
  Name: string;
  Field: string;
  Width?:string|number
}

interface TableProps {
  headers: IHeader[];
  data: any[];
  style?: React.CSSProperties;
}

const DynamicTable = (props: TableProps) => {
  const { headers, data, style, ...rest } = props;

  return (
      <Table style={style} {...rest}>
        <Table.Head>
          <Table.Row>
            {headers.map((header) => {
              return <Table.Cell width={header.Width?header.Width:"auto"} key={header.Name}>{header.Name}</Table.Cell>;
            })}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <>
            {data.map((content, index) => {
              return (
                <Table.Row key={`row-${index}`}>
                  {headers.map((element, i) => {
                    return <Table.Cell key={`cell-${element.Field}-${index}`}>{content[element.Field]}</Table.Cell>;
                  })}
                </Table.Row>
              );
            })}
          </>
          <>{data.length === 0 && <SkeletonRow rowCount={5} columnCount={headers.length} />}</>
        </Table.Body>
      </Table>
  );
};
export default DynamicTable;
